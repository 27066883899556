import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/Total_Digital_Logo.svg";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavigation = (id) => {
    if (!isMobile) {
      if (window.location.pathname === "/") {
        handleScroll(id);
      } else {
        navigate("/#" + id);
        setTimeout(() => handleScroll(id), 0);
      }
    } else {
      handleScroll(id);
    }
  };

  const toggleSubMenu = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Lägg till denna rad för att förhindra bubbla
    const menuItem = e.target.closest(".MenuItem");
    menuItem.classList.toggle("active");
  };

  const handleScrollEvent = () => {
    const heroSection = document.querySelector(".hero-section");
    if (heroSection) {
      setScrolled(window.scrollY > heroSection.offsetHeight - 100);
    }
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (window.location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/");
      setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollEvent);
    return () => window.removeEventListener("scroll", handleScrollEvent);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Reset scroll position and scrolled state when location changes
    window.scrollTo({ top: 0, behavior: "auto" });
    setScrolled(false);
  }, [location]);

  const renderLinks = () => {
    if (location.pathname === "/tdtest") {
      return (
        <>
          <li className="MenuItem">
            <a
              href="#vadartdtest"
              onClick={(e) => {
                e.preventDefault();
                handleScroll("vadartdtest");
              }}
              className={scrolled ? "black" : "white"}
            >
              Vad är TD Test?
            </a>
          </li>
          <li className="MenuItem">
            <a
              href="#funktioner"
              onClick={(e) => {
                e.preventDefault();
                handleScroll("funktioner");
              }}
              className={scrolled ? "black" : "white"}
            >
              Funktioner
            </a>
          </li>
          <li className="MenuItem">
            <a
              href="#kontakt"
              onClick={(e) => {
                e.preventDefault();
                handleScroll("kontakt");
              }}
              className={scrolled ? "black" : "white"}
            >
              Kontakt
            </a>
          </li>
        </>
      );
    } else {
      return (
        <>
          <li className="MenuItem">
            <a
              href="#services"
              onClick={(e) => {
                e.preventDefault();
                handleNavigation("services");
              }}
              className={scrolled ? "black" : "white"}
            >
              Tjänster
              <span className="arrow-icon" onClick={toggleSubMenu}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M7 10l5 5 5-5z"></path>
                </svg>
              </span>
            </a>
            <ul className="SubMenu">
              <li>
                <Link to="/maximo">Maximo tjänster</Link>
              </li>
              <li>
                <Link to="/geodata">Geografiska tjänster</Link>
              </li>
              <li>
                <Link to="/tdassetcare">TD Asset Care</Link>
              </li>
              <li>
                <Link to="/tdtest">TD Test</Link>
              </li>
            </ul>
          </li>
          <li className="MenuItem">
            <a
              href="#partners"
              onClick={(e) => {
                e.preventDefault();
                handleNavigation("partners");
              }}
              className={scrolled ? "black" : "white"}
            >
              Partners
            </a>
          </li>
          <li className="MenuItem">
            <a
              href="#about"
              onClick={(e) => {
                e.preventDefault();
                handleNavigation("about");
              }}
              className={scrolled ? "black" : "white"}
            >
              Om oss
            </a>
          </li>
          <li className="MenuItem">
            <a
              href="#contact"
              onClick={(e) => {
                e.preventDefault();
                handleNavigation("contact");
              }}
              className={scrolled ? "black" : "white"}
            >
              Kontakt
            </a>
          </li>
        </>
      );
    }
  };

  return (
    <header className={`HeaderContainer ${scrolled ? "scrolled" : ""}`}>
      <div className="Header_logoWrapper">
        <a href="/" onClick={handleLogoClick} className="Header_logoLink">
          <img
            src={logo}
            alt="Logo Total Digital"
            className={`Header_logo ${scrolled ? "black" : "white"}`}
          />
        </a>
      </div>
      <nav className="Menu">
        <ul className="MenuItems">{renderLinks()}</ul>
      </nav>
    </header>
  );
};

export default Header;
