import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import image1 from "../assets/backgrounds/maximo22.png";
import image2 from "../assets/backgrounds/maximo44.png";
import image3 from "../assets/backgrounds/sthlm22.png";
import image4 from "../assets/backgrounds/sthlm33.png";
import tt_arrows from "../assets/tt_arrows.svg";
import backgroundImageVertical from "../assets/sthlm1.png";

const images = [
  { src: image1, text: "Geografiska tjänster", link: "/geodata" },
  { src: image2, text: "TD Asset Care", link: "/tdassetcare" },
  { src: image3, text: "TD Test", link: "/tdtest" },
  { src: image4, text: "Maximo tjänster", link: "/maximo" },
];

const HeroSection = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <section className="hero-section">
        <div className="rotating-images">
          {images.map((image, index) => (
            <div
              key={index}
              className={`rotating-image ${
                index === currentImageIndex ? "active" : ""
              }`}
              style={{
                backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0)), url(${image.src})`,
              }}
            ></div>
          ))}
        </div>

        <div className="text-overlay">
          <h1>{images[currentImageIndex].text}</h1>
          <div>
            <Link to={images[currentImageIndex].link}>
              <button type="button">Läs mer</button>
            </Link>
          </div>
        </div>
      </section>

      <section className="erbjuder">
        <h2 style={{ textAlign: "center", color: "black" }}>Vad vi erbjuder</h2>
        <div className="content-container">
          <div className="text-content">
            <p style={{ fontSize: "1.6rem" }}>
              Total Digital erbjuder tjänster inom digitaliserat underhåll och
              systemstöd. Vi hjälper er med att designa en IT-plattform för ett
              strömlinjeformat och effektivt underhållsarbete. Vi har lång
              erfarenhet av konfiguration, utveckling och förvaltning av Maximo
              och vi erbjuder lösningar inom informationsmodellering och
              digitala anläggningsmodeller. Anläggningsmodellen och
              anläggningsdatabasen utgör ryggraden inom digitaliserat underhåll
              och vi erbjuder integrerade kartapplikationer för att förbättra
              upplevelsen och ge användare möjlighet att besöka anläggningen
              virtuellt.
            </p>
          </div>
          <div className="image-content">
            <img src={tt_arrows} alt="Pilar" />
          </div>
          <div className="text-content">
            <p style={{ fontSize: "1.6rem" }}>
              Med hög kvalitet och god vägledning skapar vi mervärde för våra
              kunder. Vi erbjuder heltäckande expertis inom IBM Maximo och
              ArcGIS samt kringliggande tjänster. Vår expertis och våra tjänster
              genomsyras av lång erfarenhet samt smidig och flexibel service.
              Total Digital arbetar sedan många år intensivt med att stötta
              anläggningsägare med idéer i strävan efter mer effektivt och
              träffsäkert underhåll genom implementering av digital teknologi
              och artificiell intelligens.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
