import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import "./App.css";
import "./styles/header-styles.css";
import "./styles/hero-styles.css";
import "./styles/9aeac8cd94d136d3.css";
import "./styles/9b4d0d284841ecf8.css";
import "./styles/397760f1ee70597c.css";
import "./styles/b0c319ecf06994b1.css";
import "./styles/c5cf6827e5cc4e0c.css";
import "./styles/d43522712a2c1f8e.css";
import "./styles/de23c8e85de7e626.css";
import "./styles/9cadf29f9a67e564.css";
import "./styles/532a15313b1ec5d6.css";
import TDTest from "./components/TDTestPage";
import Header from "./components/Header";
import MaximoPage from "./components/MaximoPage";
import GeodataPage from "./components/GeodataPage";
import TdAssetCarePage from "./components/TdAssetCarePage";
import HomePage from "./components/HomePage";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>
            Total Digital - experter på digitala lösningar | Total Digital
          </title>
        </Helmet>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HomePage />
              </>
            }
          />
          <Route path="/maximo" element={<MaximoPage />} />
          <Route path="/geodata" element={<GeodataPage />} />
          <Route path="/tdassetcare" element={<TdAssetCarePage />} />
          <Route path="/tdtest" element={<TDTest />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
