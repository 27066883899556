import React from "react";
import services01 from "../assets/services01.svg";
import services02 from "../assets/services02.svg";
import services03 from "../assets/services03.svg";
import services04 from "../assets/services04.svg";
import services05 from "../assets/services05.svg";
import services06 from "../assets/services06.svg";
import services07 from "../assets/services07.svg";
import services08 from "../assets/tdtest.svg";
import cloud from "../assets/cloud.svg";

const services = [
  {
    img: services01,
    title: "IBM Maximo",
    description:
      "Vi erbjuder expertis inom alla delar av underhållssystemet IBM Maximo. Några av våra expertområden är bland annat utveckling, konfiguration/anpassning, installation, design, integration och rapportutveckling.",
  },
  {
    img: services02,
    title: "Licenser och licenshantering",
    description:
      "Vi tar hand om alla licensprocesser och förenklar hanteringen av licenser. Vi tar ansvar och hjälper er genom hela kedjan av licenshantering.",
  },
  {
    img: services03,
    title: "Förvaltning och support",
    description:
      "Vi hjälper er att säkerställa användandet av IBM Maximo tillsammans med integrerade system samt specifika frågor rörande ert underhållssystem.",
  },
  {
    img: services04,
    title: "Drift",
    description:
      "Vi tar ansvar för drifthanteringen av er molntjänst, oavsett moln eller användning av flera molntjänster i en och samma infrastruktur. Vi sköter tjänsten i IBM Cloud och ansvarar även för supportärenden.",
  },
  {
    img: services05,
    title: "Utbildning",
    description:
      "Vi erbjuder kundanpassad utbildning inom IBM Maximo-applikation. Oavsett om det handlar om grundläggande kunskaper eller specifika och avancerade områden inom IBM Maximo. Vi kan hjälpa till med att kartlägga och anpassa verksamhetens processer till systemet.",
  },
  {
    img: services06,
    title: "Projekt",
    description:
      "Vi stöttar er i flera former av projekt. Allt från förändringsprojekt till migreringsprojekt. Oavsett om det handlar om att byta system eller integrationsplattform hjälper vi er anpassa det till IBM Maximo.",
  },
  {
    img: services07,
    title: "Teknisk expertis",
    description:
      "Vi erbjuder teknisk expertis för att kontrollera och hantera aktuell hälsa och prestanda inom ert IBM Maximo.",
  },
  {
    img: services08,
    title: "Automattester",
    description:
      "Med vår produkt TD Test hjälper vi er att kvalitetssäkra ert Maximo. TD Test innehåller avancerad funktionalitet för att enkelt skapa avancerade, snabba och stabila automattester för era processer och anpassningar.",
  },
];

const GeodataPage = () => {
  return (
    <main className="bg-dark">
      <section
        id="maximo-services"
        className="ServiceBlock_services__gTaD_ layout-block theme-white"
        style={{ background: "transparent", marginTop: "10vh" }}
      >
        <div className="ServiceBlock_container__ikEu8">
          <div className="ServiceBlock_intro__FIZTe">
            <h2>Våra tjänster inom Maximo</h2>
          </div>
          <div className="ServiceBlock_serviceList__3PytR">
            {services.map((service, index) => (
              <div className="ServiceCard_wrapper_noclick" key={index}>
                <div className="ServiceCard_media__f8aI1">
                  <img src={service.img} width="146.31" height="158.42" />
                </div>
                <div className="ServiceCard_text__oy4C1">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
            <div className="ServiceBlock_media__sKnGJ">
              <img src={cloud} width="330.455" height="209.29" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default GeodataPage;
