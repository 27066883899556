import React from "react";
import IBM_logo from "../assets/IBM_logo.svg";
import ESRI_logo from "../assets/ESRI_logo.png";
import PED_Logo_RGB_L from "../assets/PED_Logo_RGB_L.svg";
import bpd_logo from "../assets/bpd_logo.svg";
import maxanv_logo from "../assets/maxanv_logo.svg";
import yellow_circle from "../assets/circle.svg";

const Partners = () => {
  return (
    <section className="overflow-hidden theme-white" id="partners">
      <div className="PartnerBlock_container__nL2br">
        <div className="PartnerBlock_background__BMaCP">
          <span
            style={{
              boxSizing: "border-box",
              display: "inline-block",
              overflow: "hidden",
              width: "initial",
              height: "initial",
              background: "none",
              opacity: 1,
              border: 0,
              margin: 0,
              padding: 0,
              position: "relative",
              maxWidth: "100%",
            }}
          >
            <span
              style={{
                boxSizing: "border-box",
                display: "block",
                width: "initial",
                height: "initial",
                background: "none",
                opacity: 1,
                border: 0,
                margin: 0,
                padding: 0,
                maxWidth: "100%",
              }}
            >
              <img
                style={{
                  display: "block",
                  maxWidth: "100%",
                  width: "initial",
                  height: "initial",
                  background: "none",
                  opacity: 1,
                  border: 0,
                  margin: 0,
                  padding: 0,
                }}
                alt=""
                aria-hidden="true"
                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271064%27%20height=%271064%27/%3e"
              />
            </span>
            <img
              alt="Yellow circle"
              role="presentation"
              src={yellow_circle}
              decoding="async"
              data-nimg="intrinsic"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                boxSizing: "border-box",
                padding: 0,
                border: "none",
                margin: "auto",
                display: "block",
                width: 0,
                height: 0,
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
              }}
            />
          </span>
        </div>

        <div className="PartnerBlock_intro__hftvr">
          <h2>Partners</h2>
          <p style={{ fontSize: "2rem" }}>
            Vi anser att ett kraftfullt partnernätverk är nyckeln till framgång.
            Vi utnyttjar ofta den hävkraft vi har tillgång till genom våra
            kraftfulla partnerskap och vårt stora nätverk för att maximera
            slutresultatet för våra kunder.
          </p>
        </div>

        <div className="PartnerBlock_partnerList__eSc1f">
          <div className="PartnerCard_wrapper__uEF8L PartnerCard_size-md__90Neg">
            <div className="PartnerCard_media__c2yb6">
              <div className="PartnerCard_svgWrapper__LJHD9">
                <a
                  className="PartnerCard_link__ATFiE"
                  tabIndex="-1"
                  href="http://www.ibm.com/"
                >
                  <img src={IBM_logo} alt="IBM logo in blue" />
                </a>
              </div>
            </div>
            <div className="PartnerCard_text__dpbgP">
              <a href="http://www.ibm.com/">
                <p>
                  IBM är vår huvudpartner som äger och vidareutvecklar
                  underhållssystemet IBM Maximo.
                </p>
              </a>
            </div>
          </div>
          <div className="PartnerCard_wrapper__uEF8L PartnerCard_size-md__90Neg">
            <div className="PartnerCard_media__c2yb6">
              <div className="PartnerCard_svgWrapper__LJHD9">
                <a
                  className="PartnerCard_link__ATFiE"
                  tabIndex="-1"
                  href="http://www.ibm.com/"
                >
                  <img src={ESRI_logo} alt="ESRI logo in blue" />
                </a>
              </div>
            </div>
            <div className="PartnerCard_text__dpbgP">
              <a href="http://www.ibm.com/">
                <p>
                  Esri är den globalt marknadsledande utvecklaren av
                  GIS-teknologi. Vi har ett nära partner-samarbete och använder
                  ESRI plattformen ArcGIS i flera av de tjänster vi erbjuder.
                </p>
              </a>
            </div>
          </div>
          <div className="PartnerCard_wrapper__uEF8L PartnerCard_size-sm__Tq03Y">
            <div className="PartnerCard_media__c2yb6">
              <div className="PartnerCard_svgWrapper__LJHD9">
                <a
                  className="PartnerCard_link__ATFiE"
                  tabIndex="-1"
                  href="https://www.pedab.se/"
                >
                  <img
                    src={PED_Logo_RGB_L}
                    alt="Pedab"
                    width="145.46667"
                    height="60.373333"
                  />
                </a>
              </div>
            </div>
            <div className="PartnerCard_text__dpbgP">
              <a href="https://www.pedab.se/">
                <p>
                  <span>Pedab är vår distributör av IBM-licenser</span>
                </p>
              </a>
            </div>
          </div>
          <div className="PartnerCard_wrapper__uEF8L PartnerCard_size-lg__k8WRh">
            <div className="PartnerCard_media__c2yb6">
              <div className="PartnerCard_svgWrapper__LJHD9">
                <a
                  className="PartnerCard_link__ATFiE"
                  tabIndex="-1"
                  href="https://www.bpdzenith.com/"
                >
                  <img
                    src={bpd_logo}
                    alt="BPD Zenith logo"
                    width="1000"
                    height="290"
                  />
                </a>
              </div>
            </div>
            <div className="PartnerCard_text__dpbgP">
              <a href="https://www.bpdzenith.com/">
                <p>
                  <span>
                    Vi är BPD Zeniths nordiska partner och kan genom dem erbjuda
                    en samling paketerade lösningar och tjänster kring Maximo
                  </span>
                </p>
              </a>
            </div>
          </div>
          <div className="PartnerCard_wrapper__uEF8L PartnerCard_size-lg__k8WRh">
            <div className="PartnerCard_media__c2yb6">
              <div className="PartnerCard_svgWrapper__LJHD9">
                <a
                  className="PartnerCard_link__ATFiE"
                  tabIndex="-1"
                  href="https://www.maxanv.se/"
                >
                  <img
                    src={maxanv_logo}
                    alt="Maximo användarförening"
                    width="468.377"
                    height="106.267"
                  />
                </a>
              </div>
            </div>
            <div className="PartnerCard_text__dpbgP">
              <a href="https://www.maxanv.se/">
                <p>
                  <span>
                    Total Digital är en aktiv medlem i Sveriges största
                    användarforum för Maximoanvändare.
                  </span>
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
