import React, { useState } from "react";
import formstyle from "../form.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    company: "",
    message: "",
    gdprConsent: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://totaldigital.info/phpmailer/send_email.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const result = await response.json();
      if (result.success) {
        alert("Meddelandet har skickats!");
      } else {
        alert("Ett fel uppstod: " + result.message);
      }
    } catch (error) {
      alert("Ett fel uppstod vid anslutningen till servern.");
    }
  };

  return (
    <section
      id="kontakta-oss"
      className="layout-block theme-darkGrey"
      style={{ backgroundColor: `var(--backgroundColor)` }}
    >
      <div className="FormBlock_container__jguj0">
        <h2>Kontakt</h2>
        <p>Vill ni veta mer om oss? Kontakta oss i dag!</p>

        <div className="_form_4 FormBlock_activecampaignForm__oSp_4">
          <style>
            @import
            url(https://fonts.bunny.net/css?family=ibm-plex-sans:400,600);
          </style>

          <form
            onSubmit={handleSubmit}
            id="_form_65E98FA18C630_"
            className="_form _form_4 _inline-form"
            noValidate=""
          >
            <div className="_form-content">
              <div className="_form_element _x32898893 _full_width">
                <label htmlFor="fullname" className="_form-label">
                  Namn
                </label>
                <div className="_field-wrapper">
                  <input
                    type="text"
                    id="fullname"
                    name="fullname"
                    placeholder="Skriv ditt namn"
                    value={formData.fullname}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="_form_element _x01757596 _full_width">
                <label htmlFor="email" className="_form-label">
                  E-post*
                </label>
                <div className="_field-wrapper">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Skriv din e-post"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="_form_element _field4 _full_width">
                <label htmlFor="mobile" className="_form-label">
                  Telefon
                </label>
                <div className="_field-wrapper">
                  <input
                    type="text"
                    id="mobile"
                    name="mobile"
                    placeholder="Skriv ditt telefonnummer"
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="_form_element _field1 _full_width">
                <label htmlFor="company" className="_form-label">
                  Företag
                </label>
                <div className="_field-wrapper">
                  <input
                    type="text"
                    id="company"
                    name="company"
                    placeholder="Skriv ditt företagsnamn"
                    value={formData.company}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="_form_element _field5 _full_width">
                <label htmlFor="message" className="_form-label">
                  Meddelande
                </label>
                <div className="_field-wrapper">
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Skriv ditt meddelande"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
              </div>
              <div className="_form_element _field6 _full_width">
                <fieldset className="_form-fieldset">
                  <div className="_row">
                    <legend className="_form-label">GDPR-godkännande</legend>
                  </div>
                  <div className="_row _checkbox-radio">
                    <input
                      id="gdprConsent"
                      type="checkbox"
                      name="gdprConsent"
                      checked={formData.gdprConsent}
                      onChange={handleChange}
                    />
                    <span>
                      <label htmlFor="gdprConsent">
                        Jag tillåter Total Digital att lagra och behandla mina
                        personuppgifter och ta emot kommunikation via e-post.
                      </label>
                    </span>
                  </div>
                </fieldset>
              </div>
              <div className="_button-wrapper _full_width">
                <button id="_form_4_submit" className="_submit" type="submit">
                  Kontakta oss
                </button>
              </div>
            </div>
            <div className="_form-thank-you" style={{ display: "none" }}></div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
