import React from "react";
import aboutstaff from "../assets/aboutstaff.svg";

const About = () => {
  return (
    <section
      id="om-total-digital"
      className="TextMediaBlock_wrapper__fd2J4 theme-lightGrey undefined"
    >
      <div className="TextMediaBlock_content__F8JY_ TextMediaBlock_media-right__34Pzp">
        <div className="TextMediaBlock_text__gFT5_">
          <div>
            <h2>Om Total Digital</h2>
            <p>
              Vårt motto är ”Lilla företagets smidighet med det stora företagets
              styrka”. Det strävar vi ständigt efter att uppnå. Vårt
              förhållandevis lilla företag opererar smidigt och flexibelt med
              korta beslutsvägar och platt organisation, samtidigt som vi
              utnyttjar den breda och djupa expertis vi besitter.
              <br />
              <br />
              På Total Digital har vi omfattande expertis inom IBM Maximo och
              ArcGIS. Våra team består av specialister med djupgående kunskaper
              inom dessa områden, där vi både har en stark bakgrund inom IBM och
              erfarenheter av Maximo, och även en stark kompetens inom
              Geoinformatik och ArcGIS. Med i genomsnitt över 20 års erfarenhet
              är våra medarbetare experter på att leverera lösningar som är
              skräddarsydda efter specifika behov.
              <br />
              <br />
              Vi är unika i vår förmåga att sammanföra Maximo och ArcGIS för att
              skapa integrerade lösningar som ökar både funktionalitet och
              effektivitet för våra kunder. Denna förmåga att kombinera
              expertiser gör oss till en värdefull partner för de som söker
              innovativa och välintegrerade tekniklösningar.
              <br />
              <br />
              Vi tror på långsiktighet. Givetvis ur ett samarbetsperspektiv, men
              framför allt ur ett kvalitetsperspektiv. För att nå dit krävs hög
              service och expertis samt tillit och tillgänglighet – något vi på
              Total Digital har svart bälte i.
            </p>
          </div>
        </div>
        <div className="TextMediaBlock_media__PqTtW undefined">
          <div className="TextMediaBlock_mediaInner__TNera">
            <img
              alt="Drawing of four people of different genders and ethnicities"
              src={aboutstaff}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                boxSizing: "border-box",
                padding: 0,
                border: "none",
                margin: "auto",
                display: "block",
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
