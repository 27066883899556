import React from "react";
import cloud from "../assets/cloud.svg";
import jordglob from "../assets/jordglob.svg";
import plats from "../assets/Plats.svg";
import rails from "../assets/ralisar.svg";
import slalomrails from "../assets/slalomraÌls.svg";

const services = [
  {
    img: jordglob,
    alt: "Jordglob ikon",
    title: "Geografisk data",
    description:
      "Vi integrerar Asset Care med geografisk data för er anläggning. Geografisk data kan utgöra allt från positionsbestämd anläggnings- eller verksamhetsinformation till olika typer av bakgrundskartor och satellitbilder. Via kopplingen får ni tillgång till inte bara geografiska positioner utan även viktig attributdata som ger ytterligare information om platser och inventarier. En anläggning med bra geografisk data kan även virtualiseras och utgöra din verksamhets digitala tvilling.",
  },
  {
    img: slalomrails,
    alt: "Slalomräls ikon",
    title: "Geografisk analys",
    description:
      "Geografiska data används med fördel för att analysera mönster och samband, som exempelvis positionsförändringar, olika typer av ”hot spots”, alltså platser mad avvikande mönster eller anomalier. Tillgången på geografiska indata bestämmer vilka analyser som är möjliga, vi har exempel på hur man kan analysera förändringar på sub millimeternivå för olika tillämpningar.",
  },
  {
    img: plats,
    alt: "Pin ikon",
    title: "Geografiskt beslutsstöd",
    description:
      "Integrationen med GIS möjliggör att ge visuell förståelse av komplex geografisk information genom presentation av kartor och diagram, vilket underlättar förståelsen av mönster och trender. Den geografiska datan kan även användas till att bedöma risker och sårbarheter i er anläggning.",
  },
  {
    img: rails,
    alt: "Rälsar ikon",
    title: "Laserdatabearbetning och automatisering",
    description:
      "Ett område med växande antal tillämpningar är analys av laserdata eller LiDAR. Extrahering av positionsbestämd information ur laserdata ger oanade möjligheter inom anläggningsinformation såsom asset/inventariebestämning, 3D-modellering och indata för visualisering och digitala tvillingar. Vi är specialister på informationsförädling genom att automatisera laserbearbetningen för en effektiv process från rådata till färdig produkt.",
  },
];

const GeodataPage = () => {
  return (
    <main className="bg-dark">
      <section
        id="geodata-services"
        className="ServiceBlock_services__gTaD_ layout-block theme-white"
        style={{ background: "transparent", marginTop: "10vh" }}
      >
        <div className="ServiceBlock_container__ikEu8">
          <div className="ServiceBlock_intro__FIZTe">
            <h2>Geografiska tjänster och funktioner</h2>
          </div>
          <div className="ServiceBlock_serviceList__3PytR">
            {services.map((service, index) => (
              <div className="ServiceCard_wrapper_noclick" key={index}>
                <div className="ServiceCard_media__f8aI1">
                  <img
                    src={service.img}
                    width="146.31"
                    height="158.42"
                    alt={service.alt}
                  />
                </div>
                <div className="ServiceCard_text__oy4C1">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
            <div
              className="ServiceBlock_media__sKnGJ"
              style={{ left: "-200px" }}
            >
              <img src={cloud} alt="Moln" width="330.455" height="209.29" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default GeodataPage;
