import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import tdtestlogo from "../assets/TDTEST_logo.svg";
import exempeltest from "../assets/exempel-test.png";
import rapportering from "../assets/rapportering.png";
import automatiserade from "../assets/automatiserade.png";
import speed from "../assets/speed.svg";
import safe from "../assets/safe.svg";
import money from "../assets/money.svg";
import userfriendly from "../assets/user.svg";
import gear from "../assets/gear.svg";
import "../styles/TdTest.css";
import { Link, useNavigate } from "react-router-dom";

const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    script.onload = () => resolve(src);
    script.onerror = () => reject(new Error(`Failed to load script ${src}`));
    document.body.appendChild(script);
  });
};

const TdTest = () => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const scriptSources = [
      "./_app-b8bd98406b5566f4.js",
      "./_buildManifest.js",
      "./_ssgManifest.js",
      "./4c744e84.6b602494b6476850.js",
      "./5D-214ff6422c20e7aa.js",
      "./8bd53eb9.413fcf4b33a69730a.js",
      "./69bd6bf3-92d30e8a88c8f67d.js",
      "./146.b7324cf7ea6a9487.js",
      "./162.f1eaf4352abd927e.js",
      "./222-ee6826e6ee053d245.js",
      "./225.fe36e70b0b8d1ff0.js",
      "./392.88077c751117fd94.js",
      "./414.427f148abf217cfc.js",
      "./469.764e1f026e064ce.js",
      "./502-fea3562f8a0e8b1.js",
      "./746-94c6f8c45a7694b5.js",
      "./783.d92f3a6c68189570.js",
      "./982.66f3eaa07e5e2190.js",
      "./framework-900934921c83a903.js",
      "./index-2ff8cc598bd14066.js",
      "./index-96ca2263ab0eaabc.js",
      "./main-adb4a46865e488e5.js",
      "./polyfills-78c92fac7aa8fdd8.js",
      "./webpack-85d23f916d574b13.js",
    ];

    Promise.all(scriptSources.map((src) => loadScript(src)))
      .then(() => console.log("All scripts loaded"))
      .catch((err) => console.error(err));
  }, []);

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (window.location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/");
      setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
    }
  };

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavigation = (id) => {
    handleScroll(id);
  };

  return (
    <body className="tdtest">
      <div className="theme-default">
        <section id="Hero" className="layout-block theme-tdtest">
          <div className="HeroCard_wrapper__4Q5aw HeroCard_textCentered__HtQ96">
            <h1 className="HeroCard_headingText__ByzEW">
              Kvalitetssäkra ditt IBM Maximo
            </h1>
            <div className="HeroCard_copy__HvA1B">
              <p className="hero">
                TD Test är det automatiserade testverktyget för både IBM Maximo
                7.x och nyaste IBM Maximo Application Suite.
              </p>
              <div style={{ marginTop: "2.5rem" }}>
                <a href="/#kontakta-oss">
                  <button type="button">Kontakta oss</button>
                </a>
              </div>
            </div>
            <div className="HeroCard_media__7Ubu6"></div>
          </div>
        </section>

        <section
          id="vadartdtest"
          className="TextMediaBlock_wrapper__fd2J4 theme-tdtest TextMediaBlock_tdtest__9j60y"
        >
          <div className="TextMediaBlock_content__F8JY_ TextMediaBlock_media-right__34Pzp">
            <div className="TextMediaBlock_text__gFT5_">
              <div>
                <h2>Vad är TD Test?</h2>
                <p>
                  <strong>
                    <span>TD Test </span>
                  </strong>
                  <span>
                    är verktyget för automattester som fungerar lika bra för IBM
                    Maximo 7.x som för nyaste IBM Maximo Application Suite.
                  </span>
                </p>
                <p>
                  <span>
                    Vill ni vara säkra på att era anpassningar fungerar lika bra
                    i senaste versionen av IBM Maximo som i er föregående
                    version är{" "}
                    <strong>
                      <span>TD Test</span>
                    </strong>{" "}
                    verktyget för Er!
                  </span>
                </p>
                <p>
                  <strong>
                    <span>TD Test </span>
                  </strong>
                  <span>
                    är till exempel perfekt för att verifiera en lyckad
                    uppgradering till MAS!
                  </span>
                </p>
              </div>
            </div>

            <div className="TextMediaBlock_media__PqTtW undefined">
              <div className="TextMediaBlock_mediaInner__TNera">
                <img
                  alt="Pilar"
                  src={tdtestlogo}
                  decoding="async"
                  data-nimg="fill"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    boxSizing: "border-box",
                    padding: 0,
                    border: "none",
                    margin: "auto",
                    display: "block",
                    width: 0,
                    height: 0,
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <section id="cards" className="undefined layout-block theme-tdtest">
          <div className="CardBlock_content__jIHkV">
            <div className="CardBlock_intro___qsn_">
              <h2>Vad kan TD Test?</h2>
              <p></p>
            </div>
            <div className="CardBlock_cardList__JJb4I">
              <div className="Card_wrapper__lHSgL">
                <div>
                  <div className="Card_heading__rgi0j">
                    <h3>Testar Maximo med hjälp av Maximo</h3>
                  </div>
                  <div className="Card_text__C_ZBJ">
                    <p>
                      <strong>
                        <span>TD Test </span>
                      </strong>
                      <span>
                        pratar via Maximo med både gränssnittet och
                        affärsobjekten (MBO:er)
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span>Maximos </span>
                        <strong>
                          <span>gränssnitt </span>
                        </strong>
                        <span>
                          testas utan krav på webbläsare eller web-driver
                        </span>
                      </li>
                      <li>
                        <span>Interna Maximo-funktioner kan testas via </span>
                        <strong>
                          <span>affärsobjekten </span>
                        </strong>
                        <span>(t.ex. Arbetsorder, FU, Förråd)</span>
                      </li>
                      <li>
                        <strong>
                          <span>Återrapportering</span>
                        </strong>
                        <span> och </span>
                        <strong>
                          <span>historik </span>
                        </strong>
                        <span>av körda test</span>
                      </li>
                      <li>
                        <span>Hantering av</span>
                        <strong>
                          <span> testdata - </span>
                        </strong>
                        <span>t.ex. </span>
                        <strong>
                          <span>databasisolering </span>
                        </strong>
                        <span>
                          som gör att databasen lämnas opåverkad efter test.
                          Samma test och data kan köras om och om. Gammal
                          testdata finns inte kvar och skräpar ner
                        </span>
                      </li>
                      <li>
                        <p>
                          <strong>
                            <span>Logghantering</span>
                          </strong>
                          <span>
                            – samlar in logginformation för varje testögonblick
                            för att underlätta eventuell felsökning.
                          </span>
                        </p>
                        <ul>
                          <li>
                            <span>
                              Information kan hämtas från Maximo-, Java- och
                              applikationsserverloggar
                            </span>
                          </li>
                          <li>
                            <span>
                              Loggutskrifter vid aktuell tidpunkt för körning
                              finns sparad med enkel åtkomst.
                            </span>
                          </li>
                          <li>
                            <span>Skärmbilder sparas för varje teststeg</span>
                          </li>
                          <li>
                            <span>
                              Detaljnivån på loggar kan styras per test och
                              påverkar inte Maximos allmänna logginställning.
                            </span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="Card_wrapper__lHSgL">
                <div>
                  <div className="Card_heading__rgi0j">
                    <h3>Heltäckande automattester för Ert Maximo</h3>
                  </div>
                  <div className="Card_text__C_ZBJ">
                    <p>
                      <span>Med </span>
                      <strong>
                        <span>TD Test </span>
                      </strong>
                      <span>
                        kan du skapa heltäckande tester som verifierar de mest
                        kritiska delarna i ert Maximo
                      </span>
                    </p>
                    <p>
                      <span>
                        TD Test stöder automattester som kontrollerar:
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span>Maximos användargränssnitt (webb)</span>
                      </li>
                      <li>
                        <span>
                          Funktioner och egenskaper i Maximos affärsobjekt (MBO)
                        </span>
                      </li>
                      <li>
                        <span>Integrationer (MIF)</span>
                      </li>
                      <li>
                        <span>Systemfunktioner</span>
                      </li>
                      <li>
                        <span>Anpassningar (Java/Automatiseringsscript)</span>
                      </li>
                      <li>
                        <span>Konfigureringar</span>
                      </li>
                      <li>
                        <span>Era verksamhetsprocesser i Maximo</span>
                      </li>
                    </ul>
                    <p>
                      <span></span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="Card_wrapper__lHSgL">
                <div>
                  <div className="Card_heading__rgi0j">
                    <h3>Rapportering</h3>
                  </div>
                  <div className="Card_text__C_ZBJ">
                    <p>
                      <span>
                        TD Test har en användarvänlig återrapportering av körda
                        test
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span>Intuitivt webbgränssnitt</span>
                      </li>
                      <li>
                        <span>Information visas per teststeg</span>
                      </li>
                      <li>
                        <span>Tydlig info om vad som gått fel</span>
                      </li>
                      <li>
                        <span>Aktuellt felmeddelande</span>
                      </li>
                      <li>
                        <span>Utdrag från logg vid feltidpunkt</span>
                      </li>
                      <li>
                        <span>
                          Skärmbilder från Maximo sparas vid varje teststeg
                        </span>
                      </li>
                      <li>
                        <span>
                          Historik och resultat över tidigare körningar
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="Card_image__Kj4yP">
                    <img src={rapportering} alt="Screenshot of TDTest" />
                  </div>
                </div>
              </div>
              <div className="Card_wrapper__lHSgL">
                <div>
                  <div className="Card_heading__rgi0j">
                    <h3>Automatiserade testkörningar</h3>
                  </div>
                  <div className="Card_text__C_ZBJ">
                    <p>
                      <span>Med </span>
                      <strong>
                        <span>TD Test </span>
                      </strong>
                      <span>
                        kan du enkelt och flexibelt välja hur du vill köra dina
                        test.
                      </span>
                    </p>
                    <ul>
                      <li>
                        <span>
                          Enkelt att köra vid behov som stöd vid utveckling och
                          förändring
                        </span>
                      </li>
                      <li>
                        <span>
                          Automattester kan köras via automationsserver (t.ex.
                          Jenkins) men det är inget krav.
                        </span>
                      </li>
                      <li>
                        <span>
                          Test kan köras enskilda eller grupperade (sviter)
                        </span>
                      </li>
                      <li>
                        <span>Testkörningar kan schemaläggas</span>
                      </li>
                      <li>
                        <span>
                          Automattester kan köras grafiskt via webb eller via
                          konsol
                        </span>
                      </li>
                      <p>
                        <span></span>
                      </p>
                    </ul>
                  </div>
                  <div className="Card_image__Kj4yP">
                    <img src={automatiserade} alt="Automattestkörning" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="funktioner"
          className="undefined layout-block theme-tdtest"
        >
          <div className="CardBlock_content__jIHkV">
            <div className="CardBlock_intro___qsn_">
              <h2>Funktioner</h2>
              <p></p>
            </div>
            <div className="CardBlock_cardList__JJb4I">
              <div className="Card_wrapper__lHSgL">
                <div className="Card_iconContainer__u2azb">
                  <img
                    className="Card_icon-small__gI2uH"
                    src={gear}
                    alt="Gear icon"
                  />
                </div>
                <div>
                  <div className="Card_heading__rgi0j">
                    <h3>Anpassningsbart</h3>
                  </div>
                  <div className="Card_text__C_ZBJ Card_contentMargin__5uUrT">
                    <p>
                      <strong>
                        <span>TD Test </span>
                      </strong>
                      <span>
                        kan användas för att automattesta i princip vilken
                        applikation som helst, men har framför allt mycket
                        utbyggda funktioner för just IBM Maximo. Ni kan enkelt
                        lägga till egna hjälpfunktioner med anrop direkt mot IBM
                        Maximo för att säkerställa funktionaliteten av era
                        anpassningar och verksamhetsprocesser.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="Card_wrapper__lHSgL">
                <div className="Card_iconContainer__u2azb">
                  <img
                    className="Card_icon-small__gI2uH"
                    src={speed}
                    alt="Speed icon"
                  />
                </div>
                <div>
                  <div className="Card_heading__rgi0j">
                    <h3>Snabbt</h3>
                  </div>
                  <div className="Card_text__C_ZBJ Card_contentMargin__5uUrT">
                    <p>
                      <span>
                        Då våra tester använder IBM Maximos eget ramverk går
                        tester mycket snabbare jämfört med tester som körs via
                        en webbläsare. Vi har mätt upp tester som går upp till
                        100 gånger snabbare via{" "}
                      </span>
                      <strong>
                        <span>TD Test</span>
                      </strong>
                      <span>.</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="Card_wrapper__lHSgL">
                <div className="Card_iconContainer__u2azb">
                  <img
                    className="Card_icon-small__gI2uH"
                    src={safe}
                    alt="Shield icon"
                  />
                </div>
                <div>
                  <div className="Card_heading__rgi0j">
                    <h3>Stabilt</h3>
                  </div>
                  <div className="Card_text__C_ZBJ Card_contentMargin__5uUrT">
                    <p>
                      <strong>
                        <span>TD Test </span>
                      </strong>
                      <span>
                        kommunicerar direkt med IBM Maximo och inte via
                        webbläsare och har därför inte de problem och brister
                        som testramverk och verktyg som testar via sk webdriver
                        (t.ex. Selenium och Katalon) har. IBM Maximos
                        webbgränssnitt testas i samma stund som det genereras av
                        applikationen, dvs innan det når en webbläsare. Detta
                        gör testen både stabila, pålitliga och snabba.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="Card_wrapper__lHSgL">
                <div className="Card_iconContainer__u2azb">
                  <img
                    className="Card_icon-small__gI2uH"
                    src={money}
                    alt="Magnifying glass with dollar sign icon"
                  />
                </div>
                <div>
                  <div className="Card_heading__rgi0j">
                    <h3>Kostnadseffektivt</h3>
                  </div>
                  <div className="Card_text__C_ZBJ Card_contentMargin__5uUrT">
                    <p>
                      <strong>
                        <span>TD Test </span>
                      </strong>
                      <span>
                        är enkelt att installera, är resurssnålt och kräver inga
                        egna servrar eller hårdvara. Även utveckling av
                        anpassade automattester för Er verksamhet kommer att
                        göras tidseffektivt eftersom{" "}
                      </span>
                      <strong>
                        <span>TD Test </span>
                      </strong>
                      <span>
                        är lättförståeligt och intuitivt med fördefinierade
                        metoder för kommunikation både mot IBM Maximos
                        användargränssnitt och affärslogik.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="Card_wrapper__lHSgL">
                <div className="Card_iconContainer__u2azb">
                  <img
                    className="Card_icon-small__gI2uH"
                    src={userfriendly}
                    alt="User icon"
                  />
                </div>
                <div>
                  <div className="Card_heading__rgi0j">
                    <h3>Användarvänligt</h3>
                  </div>
                  <div className="Card_text__C_ZBJ Card_contentMargin__5uUrT">
                    <p>
                      <strong>
                        <span>TD Test </span>
                      </strong>
                      <span>
                        har ett intuitivt gränssnitt med bland annat:{" "}
                      </span>
                    </p>
                    <ul>
                      <li>
                        <p>
                          <span>Tydlig återrapportering av körda tester </span>
                        </p>
                        <ul>
                          <li>
                            <p>
                              <span>
                                Uppföljning och översikt av historiska körningar
                              </span>
                            </p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <p>
                              <span>
                                Exakt återkoppling till var ett test går fel
                              </span>
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          <span>Loggöversikt och sammandrag</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <span>
                            Skärmbilder från testkörningar finns sparade för
                            varje teststeg
                          </span>
                        </p>
                        <p>
                          <span>
                            Funktioner som förenklar hantering av testdata
                          </span>
                        </p>
                        <p>
                          <span></span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="Card_wrapper__lHSgL">
                <div>
                  <div className="Card_heading__rgi0j">
                    <h3>Exempel på test</h3>
                  </div>
                  <div className="Card_text__C_ZBJ"></div>
                  <div className="Card_image__Kj4yP">
                    <img
                      src="https://admin.tdtest.se/media/Screenshot kÃ¶rningsdetaljer-1.png"
                      alt="Exempel på test"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="Jämförelse" className="layout-block theme-tdtest">
          <div className="PromoTable_promotableContainer__jFDKt">
            <div className="PromoTable_promotableTitles__1VupB">
              <h3></h3>
              <p>
                En jämförelse mellan TD Test och andra sätt att automattesta
                Maximo
              </p>
            </div>
            <div className="PromoTable_promotableHeader__66tjS">
              <div className="">
                <p>Funktioner</p>
              </div>
              <div className="">
                <p>Tester med hjälp av webdriver (t.ex Selenium)</p>
              </div>
              <div className="">
                <p>TD Test</p>
              </div>
            </div>
            <div className="PromoTable_promotableRows__xo0gj">
              <div className="PromoTable_promoTableRow__q2qeP">
                <div className="">
                  <p>
                    Dynamisk anpassning av test efter gränssnittsförändringar i
                    Maximo
                  </p>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    className="PromoTable_nocheck__5lRyK"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                  </svg>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    className="PromoTable_greencheck__s8J1S"
                    height="30"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                  </svg>
                </div>
              </div>
              <div className="PromoTable_promoTableRow__q2qeP">
                <div className="">
                  <p>
                    Klarar att köra samma test mot olika Maximo-versioner utan
                    omskrivning av test
                  </p>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    className="PromoTable_nocheck__5lRyK"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                  </svg>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    className="PromoTable_greencheck__s8J1S"
                    height="30"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                  </svg>
                </div>
              </div>
              <div className="PromoTable_promoTableRow__q2qeP">
                <div className="">
                  <p>Inbyggt stöd för test av Maximo-integrationer</p>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    className="PromoTable_nocheck__5lRyK"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                  </svg>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    className="PromoTable_greencheck__s8J1S"
                    height="30"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                  </svg>
                </div>
              </div>
              <div className="PromoTable_promoTableRow__q2qeP">
                <div className="">
                  <p>Inbyggt stöd för test av Maximos affärslogik (backend)</p>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    className="PromoTable_nocheck__5lRyK"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                  </svg>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    className="PromoTable_greencheck__s8J1S"
                    height="30"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                  </svg>
                </div>
              </div>
              <div className="PromoTable_promoTableRow__q2qeP">
                <div className="">
                  <p>
                    Kan köra tester schemalagt utan krav på automatiseringsmotor
                    (t.ex. Jenkins)
                  </p>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    className="PromoTable_nocheck__5lRyK"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                  </svg>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    className="PromoTable_greencheck__s8J1S"
                    height="30"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                  </svg>
                </div>
              </div>
              <div className="PromoTable_promoTableRow__q2qeP">
                <div className="">
                  <p>
                    Återrapportering av test med utförlig körningsinformation
                    (t.e.x för felsökning)
                  </p>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    className="PromoTable_nocheck__5lRyK"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                  </svg>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    className="PromoTable_greencheck__s8J1S"
                    height="30"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                  </svg>
                </div>
              </div>
              <div className="PromoTable_promoTableRow__q2qeP">
                <div className="">
                  <p>Tester som inte påverkar systemet/databasen</p>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    className="PromoTable_nocheck__5lRyK"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                  </svg>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    className="PromoTable_greencheck__s8J1S"
                    height="30"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                  </svg>
                </div>
              </div>
              <div className="PromoTable_promoTableRow__q2qeP">
                <div className="">
                  <p>Test av olika webbläsare och webbläsarversioner</p>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    className="PromoTable_greencheck__s8J1S"
                    height="30"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                  </svg>
                </div>
                <div className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 448 512"
                    className="PromoTable_nocheck__5lRyK"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="kontakt" className="layout-block theme-tdtest">
          <div className="FormBlock_container__jguj0">
            <h2>Kontakt</h2>
            <p>Vill ni veta mer om TD Test? Kontakta oss i dag!</p>
            <div></div>
            <div className="_form_10 FormBlock_activecampaignForm__oSp_4">
              <form
                method="POST"
                // action="https://totaldigital.activehosted.com/proc.php"
                action="https://totaldigital.info/phpmailer/send_email.php"
                id="_form_66A0C2CFE44EB_"
                className="_form _form_10 _inline-form _dark"
                novalidate=""
                data-styles-version="5"
              >
                <input
                  type="hidden"
                  name="u"
                  value="66A0B34FE5E80"
                  data-name="u"
                />
                <input type="hidden" name="f" value="10" data-name="f" />
                <input type="hidden" name="s" data-name="s" />
                <input type="hidden" name="c" value="0" data-name="c" />
                <input type="hidden" name="m" value="0" data-name="m" />
                <input type="hidden" name="act" value="sub" data-name="act" />
                <input type="hidden" name="v" value="2" data-name="v" />
                <input
                  type="hidden"
                  name="or"
                  value="e7100b06ffa84c857071dc62293b3350"
                  data-name="or"
                />
                <div className="_form-content">
                  <div className="_form_element _x32898893 _full_width">
                    <label for="fullname" className="_form-label">
                      Namn
                    </label>
                    <div className="_field-wrapper">
                      <input
                        type="text"
                        id="fullname"
                        name="fullname"
                        placeholder="Skriv ditt namn"
                        data-name="fullname"
                      />
                    </div>
                  </div>
                  <div className="_form_element _x01757596 _full_width">
                    <label for="email" className="_form-label">
                      E-post*
                    </label>
                    <div className="_field-wrapper">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Skriv din e-post"
                        required=""
                        data-name="email"
                      />
                    </div>
                  </div>
                  <div className="_form_element _field4 _full_width">
                    <label for="field[4]" className="_form-label">
                      Telefon
                    </label>
                    <div className="_field-wrapper">
                      <input
                        type="text"
                        id="field[4]"
                        name="field[4]"
                        value=""
                        placeholder="Skriv ditt telefonnummer"
                        data-name="mobile"
                      />
                    </div>
                  </div>
                  <div className="_form_element _field1 _full_width">
                    <label for="field[1]" className="_form-label">
                      Företag
                    </label>
                    <div className="_field-wrapper">
                      <input
                        type="text"
                        id="field[1]"
                        name="field[1]"
                        value=""
                        placeholder="Skriv ditt företagsnamn"
                        data-name="company"
                      />
                    </div>
                  </div>
                  <div className="_form_element _field5 _full_width">
                    <label for="field[5]" className="_form-label">
                      Meddelande
                    </label>
                    <div className="_field-wrapper">
                      <textarea
                        id="field[5]"
                        name="field[5]"
                        placeholder=""
                        data-name="meddelande"
                      ></textarea>
                    </div>
                  </div>
                  <div className="_form_element _field6 _full_width">
                    <fieldset className="_form-fieldset">
                      <div className="_row">
                        <legend
                          for="field[6][]"
                          className="_form-label"
                        ></legend>
                      </div>
                      <input
                        data-autofill="false"
                        type="hidden"
                        id="field[6][]"
                        name="field[6][]"
                        value="~|"
                        data-name="gdpr_consent"
                      />
                      <div className="_row _checkbox-radio">
                        <input
                          id="field_6Jag tillåter Total Digital att lagra och behandla mina personuppgifter och ta emot kommunikation via e-post."
                          type="checkbox"
                          name="field[6][]"
                          value="Jag tillåter Total Digital att lagra och behandla mina personuppgifter och ta emot kommunikation via e-post."
                          data-name="gdpr_consent"
                        />
                        <span>
                          <label for="field_6Jag tillåter Total Digital att lagra och behandla mina personuppgifter och ta emot kommunikation via e-post.">
                            Jag tillåter Total Digital att lagra och behandla
                            mina personuppgifter och ta emot kommunikation via
                            e-post.
                          </label>
                        </span>
                      </div>
                    </fieldset>
                  </div>
                  <div className="_button-wrapper _full_width">
                    <button
                      id="_form_10_submit"
                      className="_submit"
                      type="submit"
                    >
                      Kontakta oss
                    </button>
                  </div>
                  <div className="_clear-element"></div>
                </div>
                <div
                  className="_form-thank-you"
                  style={{ display: "none" }}
                ></div>
              </form>
            </div>
          </div>
        </section>

        <footer className="theme-default">
          <div className="FooterContainer_outer__yU79X FooterContainer_themePadding__gqMAx FooterContainer_width-full__e6H7j">
            <div className="FooterContainer_boxed___I9um FooterContainer_themePadding__gqMAx">
              <div className="Footer_wrapper__1C_mQ">
                <div className="Footer_intro__PyWb1">
                  <p className="preamble">
                    TD Test är en del av Total Digitals tjänsteutbud. Oavsett
                    vilken av våra tjänster ni vill ha hjälp med erbjuder vi
                    stöd genom hela processen. För att komma i kontakt med oss
                    kan ni nå oss på följande:
                  </p>
                </div>
                <div className="Footer_footerList__F8_nT">
                  <div className="FooterCard_card__M0fsl">
                    <a
                      tabindex="-1"
                      href="/cdn-cgi/l/email-protection#254c4b434a65514a514449414c424c5144490b5640"
                    >
                      <div className="FooterCard_media__09_qg">
                        <img
                          src="https://admin.tdtest.se/media/icon-email.svg"
                          alt="https://admin.tdtest.se/media/icon-email.svg"
                        />
                      </div>
                    </a>
                    <div className="FooterCard_text__t124i">
                      <a href="/cdn-cgi/l/email-protection#244d4a424b64504b504548404d434d5045480a5741">
                        <p>
                          <span>
                            E-post: <br />
                            <span
                              className="__cf_email__"
                              data-cfemail="caa3a4aca58abea5beaba6aea3ada3beaba6e4b9af"
                            >
                              [email&#160;protected]
                            </span>
                          </span>
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="FooterCard_card__M0fsl">
                    <a tabindex="-1" href="tel:0101010070">
                      <div className="FooterCard_media__09_qg">
                        <img
                          src="https://admin.tdtest.se/media/ic_round-phone.svg"
                          alt="https://admin.tdtest.se/media/ic_round-phone.svg"
                        />
                      </div>
                    </a>
                    <div className="FooterCard_text__t124i">
                      <a href="tel:0101010070">
                        <p>
                          <span>
                            Telefon: <br />
                            010 - 10 100 70
                          </span>
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="FooterCard_card__M0fsl">
                    <a
                      tabindex="-1"
                      href="https://www.linkedin.com/company/total-digital-it-solutions/about/"
                    >
                      <div className="FooterCard_media__09_qg">
                        <img
                          src="https://admin.tdtest.se/media/icon-linkedin.svg"
                          alt="https://admin.tdtest.se/media/icon-linkedin.svg"
                        />
                      </div>
                    </a>
                    <div className="FooterCard_text__t124i">
                      <a href="https://www.linkedin.com/company/total-digital-it-solutions/about/">
                        <p>
                          <span>Total Digital på Linkedin</span>
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="FooterCard_card__M0fsl">
                    <a tabindex="-1" href="/" onClick={handleLogoClick}>
                      <div className="FooterCard_media__09_qg">
                        <img
                          src="https://admin.tdtest.se/media/TotalDigital_WhiteLogo-1.png"
                          alt="https://admin.tdtest.se/media/TotalDigital_WhiteLogo-1.png"
                        />
                      </div>
                    </a>
                    <div className="FooterCard_text__t124i">
                      <a href="/" onClick={handleLogoClick}>
                        <p>
                          <span>Total Digital hemsida</span>
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </body>
  );
};

export default TdTest;
