import React, { useState } from "react";
import "../styles/servicesStyle.css";
import services01 from "../assets/services01.svg";
import services02 from "../assets/services02.svg";
import services03 from "../assets/services03.svg";
import services04 from "../assets/services04.svg";
import services05 from "../assets/services05.svg";
import services06 from "../assets/services06.svg";
import services07 from "../assets/services07.svg";
import rails from "../assets/ralisar.svg";

interface ServiceCardProps {
  title: string;
  description: string;
  imageSrc: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  description,
  imageSrc,
}) => {
  const [flipped, setFlipped] = useState(false);

  const handleCardClick = () => {
    setFlipped(!flipped);
  };

  return (
    <div
      className={`customServiceCard_wrapper ${flipped ? "flipped" : ""}`}
      onClick={handleCardClick}
    >
      <div className="customServiceCard_inner">
        <div className="customServiceCard_front">
          <div className="customServiceCard_media">
            <img src={imageSrc} />
          </div>
          <div className="customServiceCard_text">
            <h3>{title}</h3>
          </div>
        </div>
        <div className="customServiceCard_back">
          <div className="customServiceCard_text">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const serviceCards = [
  {
    title: "Implementation av IT-stöd för tillgångsförvaltning",
    description:
      "Vi erbjuder expertis inom alla delar av systemen IBM Maximo och Esri ArcGIS för effektiv tillgångsförvaltning.",
    imageSrc: services01,
  },
  {
    title: "Applikationsutveckling och konfiguration",
    description:
      "Vi hjälper er att säkerställa användandet av IBM Maximo och Esri ArcGIS tillsammans med integrerade system för bästa resultat.",
    imageSrc: services04,
  },
  {
    title: "Sensorteknik",
    description:
      "Med vår kunskap och erfarenhet inom sensorteknik hjälper vi er att utveckla och förvalta anläggningssystemet på ett effektivare sätt.",
    imageSrc: rails,
  },
  {
    title: "Licenser och drift",
    description:
      "Vi tar hand om alla licensprocesser och förenklar hanteringen av licenser för både IBM Maximo och Esri ArcGIS.",
    imageSrc: services02,
  },
  {
    title:
      "Projektledning och rådgivning inom anläggningsinformation, digitalisering och AI",
    description:
      "Vi stöttar er i olika typer av projekt, från förändringsprojekt till migreringsprojekt, med IBM Maximo och Esri ArcGIS.",
    imageSrc: services03,
  },
  {
    title: "Utveckling och analys inom geodataområdet",
    description:
      "Vi erbjuder omfattande tjänster inom utveckling och analys av geodata för att förbättra era arbetsflöden och beslutsprocesser.",
    imageSrc: services06,
  },
  {
    title: "Tjänstebaserad och produktifierade leveranser",
    description:
      "Vi erbjuder teknisk expertis för att övervaka och hantera hälsa och prestanda inom både IBM Maximo och Esri ArcGIS.",
    imageSrc: services07,
  },
  {
    title: "Utbildning",
    description:
      "Vi erbjuder kundanpassad utbildning inom både IBM Maximo och Esri ArcGIS för att säkerställa att ni får ut det mesta av era system.",
    imageSrc: services05,
  },
];

function Services() {
  const [columnCount, setColumnCount] = React.useState(2);

  React.useEffect(() => {
    function handleResize() {
      setColumnCount(window.innerWidth > 1600 ? 2 : 1);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section
      id="services"
      className="customServiceBlock layout-block theme-white"
    >
      <div className="customServicesContainer">
        <div className="customServicesContent">
          <div className="customServicesHeader">
            <h2>Våra tjänster</h2>
            <br />
          </div>
          <div
            className="customServicesList"
            style={{
              columnCount: columnCount,
            }}
          >
            {serviceCards.map((card, index) => (
              <ServiceCard
                key={index}
                title={card.title}
                description={card.description}
                imageSrc={card.imageSrc}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
