import React from "react";
import iconemail from "../assets/icon-email.svg";
import iconphone from "../assets/icon-phone.svg";
import iconlinkedin from "../assets/icon-linkedin.svg";

const Footer = () => {
  return (
    <footer className="theme-rust">
      <div className="FooterContainer_outer__yU79X FooterContainer_themePadding__gqMAx FooterContainer_width-large__LIHVn">
        <div className="Footer_wrapper__1C_mQ">
          <div className="Footer_intro__PyWb1">
            <p className="preamble">
              Oavsett vilken av våra tjänster ni vill ha hjälp med erbjuder vi
              stöd genom hela processen. För att komma i kontakt med oss kan ni
              nå oss på följande:
            </p>
          </div>
          <div className="Footer_footerList__F8_nT Footer_cols-3__S_BW7">
            <div className="FooterCard_card__M0fsl">
              <a tabIndex="-1" href="mailto:info@totaldigital.se">
                <div className="FooterCard_media__09_qg">
                  <img
                    src={iconemail}
                    alt="https://admin.totaldigital.se/media/icon-email.svg"
                  />
                </div>
              </a>
              <div className="FooterCard_text__t124i">
                <a href="mailto:info@totaldigital.se">
                  <p>
                    <span>
                      E-post: <br />
                      info@totaldigital.se
                    </span>
                  </p>
                </a>
              </div>
            </div>
            <div className="FooterCard_card__M0fsl">
              <a tabIndex="-1" href="tel:0101010070">
                <div className="FooterCard_media__09_qg">
                  <img
                    src={iconphone}
                    alt="https://admin.totaldigital.se/media/icon-phone.svg"
                  />
                </div>
              </a>
              <div className="FooterCard_text__t124i">
                <a href="tel:0101010070">
                  <p>
                    <span>
                      Telefon: <br />
                      010 - 10 100 70
                    </span>
                  </p>
                </a>
              </div>
            </div>
            <div className="FooterCard_card__M0fsl">
              <a
                tabIndex="-1"
                href="https://www.linkedin.com/company/total-digital-it-solutions/about/"
              >
                <div className="FooterCard_media__09_qg">
                  <img
                    src={iconlinkedin}
                    alt="https://admin.totaldigital.se/media/icon-linkedin.svg"
                  />
                </div>
              </a>
              <div className="FooterCard_text__t124i">
                <a href="https://www.linkedin.com/company/total-digital-it-solutions/about/">
                  <p>
                    <span>Total Digital på Linkedin</span>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
